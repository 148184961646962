.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}
.popup{
  position: fixed;
  top: 3.73611111111111vw;
  border-radius: 0.5vw;
  z-index: 50;
}

.popuptwo{
  position: fixed;
  top: 3.73611111111111vw;
  right:3vw;
  border-radius: 0.5vw;
  z-index: 50;
}


.content{

  background-color: #FFFFFF ;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.content.show{
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}


.dropdown-content a {
  color: #F8961D;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
 

}
.course_sub_text_div {
  width: 85vw;
  height: 29.16666666666667vw;
  font-size: 3.333333333333333vw;
  margin-top: 7.222222222222222vw;
}
.dropdown-content a:hover {background-color: #FEF3E9}

.dropdown:hover .dropdown-content {
  display: block;
}

.text_navbar_getintouch{
 
  font-weight: 500;
  color: #FFFFFF;
  background-color: #D42B4D;
  border: none;
  border-radius:  4.48888888888889vw 5.2777777777777778vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  cursor: pointer;

}
.text_navbar_getintouchh{
 
  font-weight: 500;
  color: #D42B4D;
  background-color: #FFFFFF;
  border: none;
  border-radius:  4.48888888888889vw 5.2777777777777778vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  cursor: pointer;

}
.text_navbar_getintouchs{
 
  font-weight: 500;
  color: #D42B4D;
  border-radius:  4.48888888888889vw 5.2777777777777778vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  cursor: pointer;

}
.bg-text{
 
  /* Black w/opacity/see-through */
  background-image: url("../src/Assests/Mask\ group.png");
  /* Center and scale the image nicely *

  /* Center and scale the image nicely */
  background-color: rgba(0,0,0, 0.7);

  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;


}
.bg-image{

  background-image: url("../src/Assests/Mask\ group\ \(1\).png");
  /* Center and scale the image nicely *

  /* Center and scale the image nicely */
  /* background-color: rgba(0,0,0, 0.7); */

  background-repeat: no-repeat;

  background-size:cover;
  background-position: center;
}
.course_sub_text_div {
  width: 85vw;
  height: 29.16666666666667vw;
  font-size: 3.333333333333333vw;
  margin-top: 7.222222222222222vw;
}
/* div {
  background-color: red;
  animation-name: example;
  animation-duration: 4s;
}


@keyframes example {
  from {background-color: red;}
  to {background-color: yellow;}
} */

.outer-container {
  overflow: hidden;
}
.inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
}
.inner-container::-webkit-scrollbar {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
